var container = document.querySelector(".services-grid-container");
(function () {
    var isDown = false;
    var startX;
    var scrollLeft;


    container.addEventListener("mousedown", function (e) {
        isDown = true;
        container.classList.add("active");
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
    });
    container.addEventListener("mouseleave", function () {
        isDown = false;
        container.classList.remove("active");
    });
    container.addEventListener("mouseup", function () {
        isDown = false;
        container.classList.remove("active");
    });
    container.addEventListener("mousemove", function (e) {
        if (!isDown) return;
        e.preventDefault();
        var x = e.pageX - container.offsetLeft;
        var walk = (x - startX) * 3; // Velocidad de desplazamiento ajustable
        container.scrollLeft = scrollLeft - walk;
    }, { passive: false });



})();


document.getElementById("left-scroll").addEventListener("click", leftScroll, { passive: true });
document.getElementById("right-scroll").addEventListener("click", rightScroll, { passive: true });


/////////////////////////////////////////////////////

const userAgentRight = navigator.userAgent.toLowerCase();
const scrollToRight = document.querySelector(".scroll-to-right");

if (scrollToRight) {
    if (userAgentRight.indexOf("windows") !== -1 || navigator.userAgent.match(/Macintosh/i)) {
        // Show the div for macOS
        scrollToRight.style.display = "inline-block";
    } else {
        // Hide the div for other operating systems
        document.querySelector(".scroll-to-right").style.display = "none";
    }
}
// ------------------------------------
const scrollToLeft = document.querySelector(".scroll-to-left");
// Display only on desktop devices
const userAgentLeft = navigator.userAgent.toLowerCase();
/////////////////////////////////////////////////////
if (scrollToLeft) {
    if (userAgentLeft.indexOf("windows") !== -1 || navigator.userAgent.match(/Macintosh/i)) {
        scrollToLeft.style.display = "inline-block";
    } else {
        // Hide the div for other operating systems
        document.querySelector(".scroll-to-left").style.display = "none";
    }
}


/////////////////////////////////////////////////////


function rightScroll() {
    container.scrollLeft += window.innerWidth - 55; // Scroll the container 100 pixels to the right
}

function leftScroll() {
    container.scrollLeft -= window.innerWidth - 50; // Scroll the container 100 pixels to the left
}