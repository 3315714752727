import "normalize.css";
import "./index.css";

import "./mapInit";
import "./slider";

import "./validateSubmit";


import Alpine from "alpinejs";
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)
window.Alpine = Alpine;
Alpine.start();
