let map;


(function initMap() {




    map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 32.33046229285591, lng: -116.41831171366552 },
        zoom: 8,
        scrollwheel: true,
        // gestureHandling: "cooperative",
        streetViewControl: false,
        mapTypeControl: false,
        mapId: '1d834bb45e11857e'
    });




    const markers = [
        [
            "Grupo Aceves Ensenada",
            31.860473274338304,
            -116.63168475642597,
            "/src/assets/o-icon.png"
        ],
        [
            "Grupo Aceves Tijuana",
            32.540854700058134, -116.94096586304117,
            "/src/assets/o-icon.png"
        ],
        [
            "Grupo Aceves Mexicali",
            32.66808361313662, -115.4180640821034,
            "/src/assets/o-icon.png"
        ],

        [
            "Bodega San Diego",
            32.55708961559127, -116.92845188713177,
            "/src/assets/w-icon.png"
        ],







    ];


    for (let i = 0; i < markers.length; i++) {
        const currMarker = markers[i]


        const marker = new google.maps.Marker({
            position: { lat: currMarker[1], lng: currMarker[2] },
            map,
            title: currMarker[0],

            icon: {
                url: currMarker[3],

                scaledSize: new google.maps.Size(30, 30)

            },
            animation: google.maps.Animation.DROP
        });

        const infowindow = new google.maps.InfoWindow({
            content: currMarker[0],
        });

        marker.addListener("click", () => {
            map.panTo(marker.getPosition());
            infowindow.open(map, marker);
            performSearch(currMarker[1], currMarker[2]);
            // infowindow.open(map, marker);
        });
    }

})();

let ensLat = "31.860473274338304"

function performSearch(lat) {
    console.log(lat);

    if (lat == ensLat) {
        const request = {
            placeId: 'ChIJR0U4fziT2IARYogDCQb0jCE' // Reemplaza PLACE_ID_OF_YOUR_LOCATION con el ID de tu ubicación en Google Maps
        };

        const service = new google.maps.places.PlacesService(map);

        service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                const infowindow = new google.maps.InfoWindow({
                    content: place.name,
                });
                infowindow.open(map);
            }
        });

    }

}












