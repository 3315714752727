import FormValidator from "@yaireo/validator";
import Swal from "sweetalert2";
import { load } from "recaptcha-v3";

form.onsubmit = function (e) {
  e.preventDefault();// Prevent the default form submission behavior

  load("6LcXphoaAAAAAEs4FK7XCntehWPU6qWvtKoQYweH").then((recaptcha) => {
    recaptcha.execute("contact").then((token) => {
      // If the ReCaptcha verification is successful, proceed with form submission
      if (token) {
        const validatorResult = validator.checkAll(form);
        // console.log(form);

        if (validatorResult.valid) {

          const formData = new FormData(form);
          formData.append("token", token);

          fetch("sendmail.php", {
            method: "post",
            body: formData,
          })
            .then((resp) => resp.json())
            .then(function (text) {
              console.log(text); //Do something with the response, which is an array
              if (text !== undefined && text.length > 0) {
                // console.log("Error");
                Swal.fire({
                  title: "Error!",
                  html: "La validacion de Google reCAPTCHA ha fallado,<br>por favor intentelo de nuevo más tarde",
                  icon: "error",
                  iconColor: "#d2373d",
                  confirmButtonText: "CONFIRMAR",
                  confirmButtonColor: "#1f1f1f",
                });

              } else {
                Swal.fire({
                  title: "Enviado!",
                  html: "Gracias,<br>su mensaje ah sido enviado con exito",
                  icon: "success",
                  iconColor: "#A1DA81",
                  confirmButtonText: "CONFIRMAR",
                  confirmButtonColor: "#1f1f1f",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error!",
                html: "Ha ocurrido un error de conexion,<br>por favor intentelo de nuevo más tarde",
                icon: "error",
                iconColor: "#d2373d",
                confirmButtonText: "CONFIRMAR",
                confirmButtonColor: "#1f1f1f",
              });
            });
        }
        return !!validatorResult.valid;
      } else {
        console.log("Form submission blocked");
      }

    });
  });
};

const validator = new FormValidator({ events: ["change"] }, document.forms[0]);